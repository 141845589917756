import { Form, Input, Select, Button, message, Spin } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAddUserMutationMutation, useMeQuery } from '../../generated/graphql';
import styles from './UserAddForm.module.scss';
const { Option } = Select;

const UserAddForm = (): JSX.Element => {
  const history = useHistory();
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted() {
      if (!meData?.me.id) {
        history.push('/login');
      }
    },
    onError() {
      history.push('/login');
    }
  });
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [selectValue, setSelectValue] = useState(1);
  const [addUser] = useAddUserMutationMutation();

  const success = () => {
    message.success('Успішно!');
  };
  const onFinish = async (values: any) => {
    try {
      await addUser({ variables: { addUserPassword: password, addUserName: username, role: selectValue, phone } });
      success();
    } catch (e) {
      console.log(e);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (meLoading) return <Spin tip="Завантаження..." />;
  return (
    <div>
      <div className={styles.headerWrapper}>
        <h3>Додати працівника</h3>
      </div>
      <div className={styles.formWrapper}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Ім'я"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input your name!',
              },
            ]}
          >
            <Input value={username} onChange={(event) => setUserName(event.target.value)} />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password value={password} onChange={(event) => setPassword(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please input your phone!',
              },
            ]}
          >
            <Input value={phone} onChange={(event) => setPhone(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Роль"
            name="role"
            rules={[
              {
                required: true,
                message: 'Please input your role!',
              },
            ]}
          >
            <Select value={selectValue} onChange={(v: number) => setSelectValue(v)}>
              <Option value={1}>Працівник</Option>
              <Option value={2}>Адміністратор</Option>
            </Select>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Додати
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UserAddForm;
