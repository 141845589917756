import { useMeQuery, useSettingsQuery } from '../../generated/graphql';
import { useHistory } from 'react-router';
import { RoleType } from '../../types';
import { Form, Input, Layout, message, Spin } from 'antd';
import styles from './Settings.module.scss';
import SettingsForm from '../SettingsForm/SettingsForm';
import { setIsMobile } from '../../gql/cache';
import { useReactiveVar } from '@apollo/client';

const Settings = (): JSX.Element => {
  const history = useHistory();
  const isMobile = useReactiveVar(setIsMobile);

  const { data: settingsData, loading: settingsLoading } = useSettingsQuery({ fetchPolicy: 'network-only' });
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted() {
      if (!meData?.me.id) {
        history.push('/login');
      }
    },
    onError() {
      history.push('/login');
    }
  });
  if (meLoading || settingsLoading) return <Spin tip="Завантаження..." />;

  return (
    <div className={styles.settingsWrapper}>
      <Layout>
        <SettingsForm
          workEndHour={settingsData?.settings.workEndHour}
          workStartHour={settingsData?.settings.workStartHour}
          newPostMessageTemplate={settingsData?.settings.newPostMessageTemplate}
          reminderMessageTemplate={settingsData?.settings.reminderMessageTemplate}
          isNotify={settingsData?.settings.isNotify}
          isRemind={settingsData?.settings.isRemind}
          remindTime={settingsData?.settings.remindTime}
        ></SettingsForm>
      </Layout>
    </div>
  );
};

export default Settings;
