import {useReactiveVar} from '@apollo/client';
import {Button, Form, Select, DatePicker, message, Checkbox, Spin, Input, InputNumber, Radio} from 'antd';
import moment from 'moment';
import {FC, useCallback, useState} from 'react';
import {useHistory} from 'react-router';
import {
    useAddPostMutation,
    useClientsQuery,
    useEditPostMutation,
    useMeQuery,
    useRemovePostMutation,
    useServicesQuery,
    useSettingsQuery,
    useUsersQuery,
} from '../../generated/graphql';
import {setIsMobile} from '../../gql/cache';
import styles from './PostAddForm.module.scss';

interface IPostAddForm {
    predefinedDate?: Date;
    predefinedEvent?: {
        id: string;
        clientId: string;
        serviceId: string;
        comment: string;
        userId: string;
        remindBefore: boolean;
        notifyBefore: boolean;
        date: Date;
        customDuration: number;
    };
    predefinedDuration?: number;
    customSubmit?: boolean;
    predefinedUser?: string;
    onCancel?: () => void;
}

const {Option} = Select;
const PostAddForm: FC<IPostAddForm> = ({
                                           predefinedEvent,
                                           predefinedDate,
                                           predefinedDuration,
                                           customSubmit,
                                           predefinedUser,
                                           onCancel,
                                       }): JSX.Element => {
    const isMobile = useReactiveVar(setIsMobile);

    const history = useHistory();
    const {
        data: meData,
        loading: meLoading,
        error: meError,
    } = useMeQuery({
        fetchPolicy: 'network-only',
        onCompleted() {
            if (!meData?.me.id) {
                history.push('/login');
            }
        },
        onError() {
            history.push('/login');
        }
    });

    const {data: clientsData, loading: clientsLoading} = useClientsQuery({fetchPolicy: 'network-only'});
    const {data: usersData, loading: usersLoading} = useUsersQuery({fetchPolicy: 'network-only'});
    const {data: servicesData, loading: servicesLoading} = useServicesQuery({fetchPolicy: 'network-only'});
    const {data: settingsData, loading: settingsLoading} = useSettingsQuery({fetchPolicy: 'network-only'});
    const [addPost] = useAddPostMutation();
    const [editPost] = useEditPostMutation();
    const [removePost] = useRemovePostMutation();
    const [clientId, setClientId] = useState(predefinedEvent?.clientId || clientsData?.clients[0].id || '');
    const [remindBefore, setRemindBefore] = useState<boolean>(predefinedEvent ? predefinedEvent.remindBefore : true);
    const [notifyBefore, setNotifyBefore] = useState<boolean>(predefinedEvent ? predefinedEvent.notifyBefore : true);
    const [userId, setUserId] = useState(predefinedEvent?.userId || predefinedUser || '');
    const [serviceId, setServiceId] = useState(predefinedEvent?.serviceId || servicesData?.services[0].id || '');
    const [comment, setComment] = useState(predefinedEvent?.comment || '');
    const [customDuration, setCustomDuration] = useState(predefinedEvent?.customDuration || predefinedDuration || -1);
    const [selectedDuration, setSelectedDuration] = useState(predefinedEvent?.customDuration || predefinedDuration || -1);
    const [date, setDate] = useState(
        predefinedEvent ? moment(predefinedEvent.date) : predefinedDate ? moment(predefinedDate) : moment(),
    );

    const success = () => {
        message.success('Успішно!');
    };

    const onFinish = async () => {
        try {
            const res = await addPost({variables: {clientId, userId, serviceId, comment, date, notifyBefore, remindBefore, customDuration: selectedDuration}});
            success();
            history.push(`/schedule?date=${date.unix()}`);
        } catch (e) {
            console.log('error', e);
        }
    };
    const getDurationOptions = useCallback(() =>{
        const serviceDuration = servicesData?.services.find(e => e.id === serviceId)?.duration || -1;
        const options =  [
            { label: `${customDuration} хв`, value: customDuration },
            { label: `${serviceDuration} хв`, value: serviceDuration },
        ].filter(elem => elem.value > 0);

        return options;
    },[customDuration, servicesData, serviceId])
    const handleEditEvent = useCallback(async () => {
        try {
            const res = await editPost({
                variables: {
                    editPostId: predefinedEvent?.id || '',
                    clientId,
                    userId,
                    serviceId,
                    comment,
                    date,
                    notifyBefore,
                    remindBefore,
                    customDuration: selectedDuration
                },
            });
            success();
            history.push(`/schedule?date=${date.unix()}`);
        } catch (e) {
            console.log('error', e);
        }
    }, [clientId, comment, remindBefore, notifyBefore, predefinedEvent, userId, serviceId, date]);
    const handleRemoveEvent = useCallback(async () => {
        try {
            const res = await removePost({variables: {removePostId: predefinedEvent?.id || ''}});
            success();
            history.push(`/schedule?date=${date.unix()}`);
        } catch (e) {
            console.log('error', e);
        }
    }, [predefinedEvent]);
    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };
    if (meLoading) return <Spin tip="Завантаження..."/>;
    if (clientsLoading || usersLoading || servicesLoading || settingsLoading) return <Spin tip="Завантаження..."/>;
    return (
        <>
            <div className={styles.formWrapper}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    initialValues={{
                        clientId,
                        userId,
                        serviceId,
                        date,
                        notifyBefore: true,
                        isNotify: notifyBefore,
                        isRemind: remindBefore,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item
                        label="Клієнт"
                        name="clientId"
                        rules={[
                            {
                                required: true,
                                message: 'Please input client!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{width: isMobile ? '100%' : 200}}
                            placeholder="Пошук"
                            optionFilterProp="children"
                            value={clientId}
                            onChange={(v) => setClientId(v)}
                            filterOption={(input, option) => {
                                const opt = clientsData?.clients.find((client) => client.id === option?.value);
                                const key = `${opt?.lastName} ${opt?.name}`;
                                return key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            filterSort={
                                (optionA, optionB) => {
                                    const optA = clientsData?.clients.find((client) => client.id === optionA?.value);
                                    const keyA = `${optA?.lastName} ${optA?.name}`;
                                    const optB = clientsData?.clients.find((client) => client.id === optionB?.value);
                                    const keyB = `${optB?.lastName} ${optB?.name}`;
                                    return keyA.toLowerCase().localeCompare(keyB.toLowerCase());
                                }
                                //
                            }
                        >
                            {clientsData?.clients &&
                                clientsData.clients.map((client) => (
                                    <Option key={client.id} value={client.id}>
                                        {client.lastName} {client.name} ({client.phone})
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Працівник"
                        name="userId"
                        rules={[
                            {
                                required: true,
                                message: 'Please input user!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{width: isMobile ? '100%' : 200}}
                            placeholder="Пошук"
                            optionFilterProp="children"
                            value={userId}
                            onChange={(v) => setUserId(v)}
                            filterOption={(input, option) => {
                                const opt = usersData?.users.find((user) => user.id === option?.value);
                                const key = `${opt?.name}`;
                                return key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            filterSort={
                                (optionA, optionB) => {
                                    const optA = usersData?.users.find((user) => user.id === optionA?.value);
                                    const keyA = `${optA?.name}`;
                                    const optB = usersData?.users.find((user) => user.id === optionB?.value);
                                    const keyB = `${optB?.name}`;
                                    return keyA.toLowerCase().localeCompare(keyB.toLowerCase());
                                }
                                //
                            }
                        >
                            {usersData?.users &&
                                usersData.users.map((user) => (
                                    <Option key={user.id} value={user.id}>
                                        {user.name}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Послуга"
                        name="serviceId"
                        rules={[
                            {
                                required: true,
                                message: 'Please input service!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            style={{width: isMobile ? '100%' : 200}}
                            value={serviceId}
                            onChange={(v) => setServiceId(v)}
                            placeholder="Пошук"
                            optionFilterProp="children"
                            filterOption={(input, option) => {
                                const opt = servicesData?.services.find((service) => service.id === option?.value);
                                const key = `${opt?.name}`;
                                return key.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }}
                            filterSort={
                                (optionA, optionB) => {
                                    const optA = servicesData?.services.find((service) => service.id === optionA?.value);
                                    const keyA = `${optA?.name}`;
                                    const optB = servicesData?.services.find((service) => service.id === optionB?.value);
                                    const keyB = `${optB?.name}`;
                                    return keyA.toLowerCase().localeCompare(keyB.toLowerCase());
                                }
                                //
                            }
                        >
                            {servicesData?.services &&
                                servicesData.services.map((service) => (
                                    <Option key={service.id} value={service.id}>
                                        {service.name}
                                    </Option>
                                ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="Коментар"
                        name="comment"
                    >
                        <Input
                            style={{width: isMobile ? '100%' : 200}}
                            value={comment}
                            onChange={(v) => setComment(v.target.value)}
                        ></Input>
                    </Form.Item>
                    <Form.Item
                        label="Тривалість"
                        name="selectedDuration"
                    >
                        <Radio.Group
                            defaultValue={selectedDuration}
                            style={{width: isMobile ? '100%' : 200}}
                            options={getDurationOptions()}
                            onChange={(v)=> setSelectedDuration(v.target.value)}
                            value={selectedDuration}
                            optionType="button"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Дата"
                        name="date"
                        rules={[
                            {
                                required: true,
                                message: 'Please input date!',
                            },
                        ]}
                    >
                        <DatePicker
                            style={{width: isMobile ? '100%' : 200}}
                            value={date}
                            defaultValue={date}
                            format="DD-MM HH:mm"
                            showTime={{
                                defaultValue: moment('00:00', 'HH:mm'),
                                hideDisabledOptions: true,
                                disabledHours: () => {
                                    const res = [];
                                    for (let i = 0; i < settingsData!.settings.workStartHour; i++) {
                                        res.push(i);
                                    }
                                    for (let i = settingsData!.settings.workEndHour; i < 24; i++) {
                                        res.push(i);
                                    }
                                    return res;
                                },
                                disabledMinutes: () => {
                                    const res = [];
                                    for (let i = 0; i < 60; i++) {
                                        if (i % 5 !== 0) res.push(i);
                                    }
                                    return res;
                                },
                            }}
                            onChange={(v) => setDate(v || moment())}
                        />
                    </Form.Item>
                    <Form.Item label="Сповіщення" name="isNotify">
                        <Checkbox
                            style={{width: isMobile ? '100%' : 200}}
                            defaultChecked={notifyBefore}
                            onChange={() => setNotifyBefore(!notifyBefore)}
                        />
                    </Form.Item>
                    <Form.Item label="Нагадування" name="isRemind">
                        <Checkbox
                            style={{width: isMobile ? '100%' : 200}}
                            defaultChecked={remindBefore}
                            onChange={() => setRemindBefore(!remindBefore)}
                        />
                    </Form.Item>
                    <Form.Item
                        wrapperCol={{
                            offset: 0,
                            span: 16,
                        }}
                    >
                        {customSubmit ? (
                            <>
                                <div className={styles.wrapper}>
                                    {predefinedEvent ? (
                                        <Button type="primary" onClick={handleEditEvent}>
                                            Зберегти
                                        </Button>
                                    ) : (
                                        <Button type="primary" htmlType="submit">
                                            Додати
                                        </Button>
                                    )}
                                    <Button onClick={onCancel} htmlType="button">
                                        Скасувати
                                    </Button>
                                    {predefinedEvent && (
                                        <Button onClick={handleRemoveEvent} htmlType="button">
                                            Видалити
                                        </Button>
                                    )}
                                </div>
                            </>
                        ) : (
                            <Button style={{width: '100px', marginLeft: '100px'}} type="primary" htmlType="submit">
                                Додати
                            </Button>
                        )}
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default PostAddForm;
