import { Form, Input, Checkbox, Button, message, Spin } from 'antd';
import 'antd/dist/antd.css';
import _ from 'lodash';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useLoginMutationMutation, useMeQuery, User } from '../../generated/graphql';
import { setMe } from '../../gql/cache';
import styles from './Login.module.scss';

const Login = (): JSX.Element => {
  const [loginData] = useLoginMutationMutation();
  const [rememberMe, setRememberMe] = useState(true);
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const success = () => {
    message.success('This is a success message');
  };
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted() {
      if (meData?.me.id) {
        history.push('/schedule');
      }
    },
  });

  const onFinish = async (values: any) => {
    try {
      const res = await loginData({ variables: { loginPassword: password, loginName: username } });
      setMe(_.pick(res.data?.login, ['id', 'name', 'role']) as Pick<User, 'id' | 'name' | 'role'>);
      history.push('/schedule');
      success();
    } catch (e) {
      console.log('error', e);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (meLoading) return <Spin tip="Завантаження..." />;
  return (
    <>
      <div className={styles.formWrapper}>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: 'Please input your username!',
              },
            ]}
          >
            <Input value={username} onChange={(event) => setUserName(event.target.value)} />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Please input your password!',
              },
            ]}
          >
            <Input.Password value={password} onChange={(event) => setPassword(event.target.value)} />
          </Form.Item>

          <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox value={rememberMe} onChange={() => setRememberMe(!rememberMe)}>
              Remember me
            </Checkbox>
          </Form.Item>

          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Ввійти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};
export default Login;
