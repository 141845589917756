import 'antd/dist/antd.css'; 
import {
  Switch,
  BrowserRouter,
  Route
} from "react-router-dom";
import ClientAddForm from "../ClientAddForm/ClientAddForm";
import Login from "../Login/Login";
import UserAddForm from "../UserAddForm/UserAddForm";
import ServiceAddForm from "../ServiceAddForm/ServiceAddForm";
import BaseLayout from "../BaseLayout/BaseLayout";
import PostAddForm from "../PostAddForm/PostAddForm";
import Settings from '../Settings/Settings';
import { useEffect } from 'react';
import { setIsMobile } from '../../gql/cache';
import AlternativeSchedule from '../AlternativeSchedule/AlternativeSchedule';
import './App.css';

function App(): JSX.Element {
  useEffect(()=>{
    setIsMobile(window.innerWidth <= 768);
    const callback = () =>{
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', callback);
    return () => window.removeEventListener('resize', callback);
  },[])
  return (
    <BrowserRouter>
      <BaseLayout>
        <Switch>
          <Route path="/login" render={() => <Login key={Date.now()}/> }/>
          <Route path="/addClient"><ClientAddForm/></Route>
          <Route path="/addUser"><UserAddForm/></Route>
          <Route path="/addService"><ServiceAddForm/></Route>
          <Route path="/addPost"><PostAddForm/></Route>
          <Route path="/settings"><Settings/></Route>
          <Route path="/schedule" render={() => <AlternativeSchedule key={Date.now()}/>} />
          <Route path="/" render={() => <AlternativeSchedule key={Date.now()}/>} />
        </Switch>
      </BaseLayout>
    </BrowserRouter>
  );
}

export default App;
