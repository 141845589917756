import { Input, Button, Form, message, Spin } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAddClientMutationMutation, useMeQuery } from '../../generated/graphql';
import styles from './ClientAddForm.module.scss';

const ClientAddForm = (): JSX.Element => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [addClient] = useAddClientMutationMutation();
  const history = useHistory();

  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted() {
      if (!meData?.me.id) {
        history.push('/login');
      }
    },
    onError() {
      history.push('/login');
    }
  });

  const success = () => {
    message.success('Успішно!');
  };
  const onFinish = async (values: any) => {
    try {
      await addClient({ variables: { name, lastName, phone } });
      success();
    } catch (e) {
      console.log(e);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  if (meLoading) return <Spin tip="Завантаження..." />;
  return (
    <div>
      <div className={styles.headerWrapper}>
        <h3>Додати клієнта</h3>
      </div>
      <div className={styles.formWrapper}>
        <Form
          name="basic"
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Ім'я"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input value={name} onChange={(event) => setName(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Фамілія"
            name="lastName"
            rules={[
              {
                required: true,
                message: 'Please input lastName!',
              },
            ]}
          >
            <Input value={lastName} onChange={(event) => setLastName(event.target.value)} />
          </Form.Item>
          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please input phone!',
              },
            ]}
          >
            <Input value={phone} onChange={(event) => setPhone(event.target.value)} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Додати
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ClientAddForm;
