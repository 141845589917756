
import {
    ApolloClient,
    InMemoryCache,
    createHttpLink
  } from "@apollo/client";
  
  const link = createHttpLink({
    uri: `https://api-v1.nailspace.org:4000/graphql`,
    credentials: 'include'

  });
  export const client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
      
  });