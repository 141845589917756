import { useReactiveVar } from '@apollo/client';
import { DatePicker, Modal, Select, Spin, Table } from 'antd';
import moment from 'moment';
import 'moment/locale/uk'
import { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import {
  Client,
  Service,
  useClientsQuery,
  useFilterPostsQuery,
  useMeQuery,
  User,
  useServicesQuery,
  useSettingsQuery,
  useUsersQuery,
} from '../../generated/graphql';
import { setMe } from '../../gql/cache';
import styles from './AlternativeSchedule.module.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PostAddForm from '../PostAddForm/PostAddForm';
import { useHistory, useLocation } from 'react-router';
import { CustomToolbar } from '../CustomToolbar/CustomToolbar';

type Event = {
  title: string;
  start: Date;
  end: Date;
  allDay?: boolean;
};
const AlternativeSchedule = (): JSX.Element => {
  const localizer = momentLocalizer(moment);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>();
  const [selectedEvent, setSelectedEvent] = useState();
  const [selectedDuration, setSelectedDuration] = useState<number>(-1);
  const [events, setEvents] = useState<Event[]>([]);

  const predefinedDate = new URLSearchParams(useLocation().search).get('date');
  const [date, setDate] = useState<Date>(predefinedDate ? new Date(Number(predefinedDate) * 1000) : new Date());
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [visible, setVisible] = useState(false);
  const [resourceMap, setResourceMap] = useState<{ resourceId: string, resourceTitle: string}[]>([]);
  const history = useHistory();
  
  const { data: clientsData, loading: clientsLoading } = useClientsQuery({ fetchPolicy: 'network-only' });
  const { data: usersData, loading: usersLoading } = useUsersQuery({ fetchPolicy: 'network-only' });
  const { data: servicesData, loading: servicesLoading } = useServicesQuery({ fetchPolicy: 'network-only' });
  const { data: settingsData, loading: settingsLoading } = useSettingsQuery({ fetchPolicy: 'network-only' });
  const { loading: postLoading } = useFilterPostsQuery({
    variables: { filter: {} },
    skip: !selectedUserId || clientsLoading || usersLoading || servicesLoading || settingsLoading,
    onCompleted(data) {
      if(usersData){
        setResourceMap(usersData.users.sort((u1, u2) => u1.name.localeCompare(u2.name)).map(user => ({ resourceId: user.id, resourceTitle: user.name})));
      }
      const arr = [...data.filterPosts];
      if (Array.isArray(arr)) arr.sort((elem1, elem2) => elem1.date - elem2.date);
      const res = arr.map((elem) => {
        const start = moment(elem.date).toDate();
        const duration = elem.customDuration > 0 ? elem.customDuration : getService(elem.serviceId)?.duration;
        const end = moment(start).add(duration, 'minutes').toDate();
        const client = getClient(elem.clientId);
        const service = getService(elem.serviceId);
        return {
          id: elem.id,
          clientId: elem.clientId,
          userId: elem.userId,
          serviceId: elem.serviceId,
          remindBefore: elem.remindBefore,
          notifyBefore: elem.notifyBefore,
          date: elem.date,
          authorId: elem.authorId,
          customDuration: elem.customDuration,
          title: `${client ? client.lastName + ' ' + client.name + ' ' + client.phone : 'Client'} - ${service ? service.name : 'Service'} ${elem.comment.length ? '(' + elem.comment + ')': ''}`,
          start,
          end,
          resourceId: elem.userId
        };
      });
      setEvents(res);
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted() {
      if (!meData?.me.id) {
        history.push('/login');
      }
    },
    onError() {
      history.push('/login');
    }
  });


  const getClient = useCallback(
    (clientId: string): Omit<Client, 'createdAt'> | undefined => {
      return clientsData?.clients.find((client) => client.id === clientId);
    },
    [clientsData],
  );

  const getUser = useCallback(
    (userId: string): Omit<User, 'createdAt' | 'updatedAt' | 'lastLogin' | 'isHidden'> | undefined => {
      return usersData?.users.find((user) => user.id === userId);
    },
    [usersData],
  );

  const getService = useCallback(
    (serviceId: string): Service | undefined => {
      return servicesData?.services.find((service) => service.id === serviceId);
    },
    [servicesData],
  );
  const handleSelectEvent = useCallback(
    (event): void => {
      setSelectedEvent(event);
      setVisible(true);
    },
    [servicesData],
  );
  const handleSelectSlot = useCallback(
    (slotInfo): void => {
      const duration = Math.round((slotInfo.end - slotInfo.start) / 1000 / 60);
      setSelectedDuration(duration);
      setSelectedEvent(undefined);
      setStartDate(slotInfo.start);
      setSelectedUserId(slotInfo.resourceId);
      setVisible(true);
    },
    [servicesData],
  );
  useEffect(() => {
    setSelectedUserId(meData?.me.id);
  }, [meData]);
  if (meLoading) return <Spin tip="Завантаження..." />;
  if (postLoading) return <Spin tip="Завантаження..." />;
  return (
    <div className={styles.wrapper}>
      <Modal
        title="Новий запис"
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <PostAddForm
          key={Date.now()}
          predefinedUser={selectedUserId}
          predefinedEvent={selectedEvent}
          predefinedDuration={selectedDuration}
          customSubmit={true}
          predefinedDate={startDate}
          onCancel={() => setVisible(false)}
        ></PostAddForm>
      </Modal>
      <Calendar
        localizer={localizer}
        culture='ru'
        events={events}
        resources={resourceMap}
        resourceIdAccessor="resourceId"
        resourceTitleAccessor="resourceTitle"
        startAccessor="start"
        endAccessor="end"
        defaultView={'day'}
        selectable={true}
        onNavigate={(date) => setDate(date)}
        onSelectSlot={(slotInfo) => handleSelectSlot(slotInfo)}
        onSelectEvent={event => handleSelectEvent(event)}
        date={date}
        toolbar={true}
        components={{
          toolbar: () => ( <CustomToolbar date={date} updateDate={setDate}/> )
        }}
        step={15}
        min={moment()
          .hours(settingsData?.settings.workStartHour || 8)
          .minutes(0)
          .toDate()}
        max={moment()
          .hours(settingsData?.settings.workEndHour || 20)
          .minutes(0)
          .toDate()}
        style={{ height: '100%', width: '100%' }}
      />
    </div>
  );
};

export default AlternativeSchedule;
