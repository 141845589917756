import { Input, Button, Form, message, Spin } from 'antd';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { useAddServiceMutationMutation, useMeQuery } from '../../generated/graphql';
import styles from './ServiceAddForm.module.scss';

const ServiceAddForm = (): JSX.Element => {
  const history = useHistory();

  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted() {
      if (!meData?.me.id) {
        history.push('/login');
      }
    },
    onError() {
      history.push('/login');
    }
  });

  const [name, setName] = useState('');
  const [price, setPrice] = useState(100);
  const [duration, setDuration] = useState(30);
  const [addService] = useAddServiceMutationMutation();
  const success = () => {
    message.success('Успішно!');
  };
  const onFinish = async (values: any) => {
    try {
      await addService({ variables: { name, price, duration } });
      success();
    } catch (e) {
      console.log(e);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  if (meLoading) return <Spin tip="Завантаження..." />;
  
  return (
    <div>
      <div className={styles.headerWrapper}>
        <h3>Додати послугу</h3>
      </div>
      <div className={styles.formWrapper}>
        <Form
          name="basic"
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Назва"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input name!',
              },
            ]}
          >
            <Input value={name} onChange={(event) => setName(event.target.value)} />
          </Form.Item>

          <Form.Item
            label="Ціна"
            name="price"
            rules={[
              {
                required: true,
                message: 'Please input price!',
              },
            ]}
          >
            <Input value={price} onChange={(event) => setPrice(Number(event.target.value) || 0)} />
          </Form.Item>
          <Form.Item
            label="Тривалість(хв)"
            name="duration"
            rules={[
              {
                required: true,
                message: 'Please input duration!',
              },
            ]}
          >
            <Input value={duration} onChange={(event) => setDuration(Number(event.target.value))} />
          </Form.Item>
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Додати
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ServiceAddForm;
