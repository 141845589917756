import { Layout, Menu, Spin } from 'antd';
import { Header } from 'antd/lib/layout/layout';
import logo from '../../assets/logo.jpg';
import { FC, useCallback, useEffect, useState } from 'react';
import { EditOutlined, ScheduleOutlined, PlusOutlined, LogoutOutlined, SettingOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';
import { useLogoutMutation, useMeQuery } from '../../generated/graphql';
import { RoleType } from '../../types';
import styles from './BaseLayout.module.scss';
import Link from '../Link/Link';
import { useHistory } from 'react-router';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile, setMe } from '../../gql/cache';

const { Sider } = Layout;
const BaseLayout: FC = ({ children }): JSX.Element => {
  const me = useReactiveVar(setMe);
  const isMobile = useReactiveVar(setIsMobile);
  const history = useHistory();
  const [logout] = useLogoutMutation({ fetchPolicy: 'network-only' });
  const {
    data: meData,
    loading: meLoading,
    error: meError,
  } = useMeQuery({
    fetchPolicy: 'network-only',
    onCompleted() {
      if (!meData?.me.id) {
        history.push('/login');
      }
    },
    onError() {
      history.push('/login');
    },
  });
  const logoutCallback = useCallback(async () => {
    try {
      await logout();
    } catch (e) {
      console.log(e);
    }
    setMe(undefined);
    history.push('/login');
  }, [logout, history]);

  if (meLoading) return <Spin tip="Завантаження..." />;
  console.log('medata1', meData);
  return (
    <div style={{ height: '100%' }}>
      <div>
        <Header className={styles.headerWrapper}>
          {!meData?.me.id ? (
            <img src={logo}></img>
          ) : (
            <Menu mode="horizontal" theme="dark" className={styles.menu}>
              <Menu.Item key="schedule" icon={<ScheduleOutlined />}>
                <Link to="/schedule"></Link>
              </Menu.Item>
              <Menu.Item key="addPost" icon={<EditOutlined />}>
                <Link to="/addPost"></Link>
              </Menu.Item>
              <SubMenu key="add" icon={<PlusOutlined />} title="Додати">
                {meData?.me.role === RoleType.SUPER_ADMIN && (
                  <>
                    <Menu.Item key="service">
                      <Link to="/addService">Послугу</Link>
                    </Menu.Item>
                    <Menu.Item key="user">
                      <Link to="/addUser">Працівника</Link>
                    </Menu.Item>
                  </>
                )}
                <Menu.Item key="client">
                  <Link to="/addClient">Клієнта</Link>
                </Menu.Item>
              </SubMenu>
              {meData?.me.role === RoleType.SUPER_ADMIN && (
                <Menu.Item key="settings" icon={<SettingOutlined />}>
                  <Link to="/settings"></Link>
                </Menu.Item>
              )}
              <Menu.Item key="logout" onClick={() => logoutCallback()} icon={<LogoutOutlined />}></Menu.Item>
            </Menu>
          )}
        </Header>
      </div>
      <div className={styles.childrenWrapper}> {children}</div>
    </div>
  );
};
export default BaseLayout;
