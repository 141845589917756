import { FC } from "react";
import { Link as RouterLink } from "react-router-dom";
import { ILink } from "../../types";
import styles from "./Link.module.scss";

const Link: FC<ILink> = ({children, to}): JSX.Element => {

    return (<RouterLink className={styles.link} to={to}>{children}</RouterLink>)
    
}

export default Link;