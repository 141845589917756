import { RoleType } from '../../types';
import { Button, Checkbox, Form, Input, InputNumber, Layout, message, Spin } from 'antd';
import { FC, useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { setIsMobile } from '../../gql/cache';
import { useUpdateSettingsMutation } from '../../generated/graphql';
interface ISettingsForm {
  workStartHour?: number;
  workEndHour?: number;
  newPostMessageTemplate?: string;
  reminderMessageTemplate?: string;
  remindTime?: number;
  isNotify?: boolean;
  isRemind?: boolean;
}
const { TextArea } = Input;
const SettingsForm: FC<ISettingsForm> = ({
  workEndHour,
  workStartHour,
  newPostMessageTemplate,
  reminderMessageTemplate,
  remindTime,
  isNotify,
  isRemind,
}) => {
  const isMobile = useReactiveVar(setIsMobile);
  const [localWorkStartHour, setLocalWorkStartHour] = useState<number>(workStartHour || 7);
  const [localWorkEndHour, setLocalWorkEndHour] = useState<number>(workEndHour || 20);
  const [localNewPostMessageTemplate, setLocalNewPostMessageTemplate] = useState<string>(newPostMessageTemplate || '');
  const [localReminderMessageTemplate, setLocalReminderMessageTemplate] = useState<string>(
    reminderMessageTemplate || '',
  );
  const [localRemindTime, setLocalRemindTime] = useState<number>(remindTime || 18);
  const [localIsNotify, setLocalIsNotify] = useState<boolean>(!!isNotify);
  const [localIsRemind, setLocalIsRemind] = useState<boolean>(!!isRemind);
  const [updateSettings] = useUpdateSettingsMutation();
  const success = () => {
    message.success('Успішно!');
  };

  const onFinish = async () => {
    try {
      await updateSettings({
        variables: {
          workEndHour: localWorkEndHour,
          workStartHour: localWorkStartHour,
          newPostMessageTemplate: localNewPostMessageTemplate,
          reminderMessageTemplate: localReminderMessageTemplate,
          remindTime: localRemindTime,
          isRemind: localIsRemind,
          isNotify: localIsNotify,
        },
      });
      success();
    } catch (e) {
      console.log('error', e);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Початок робочого дня"
          name="workStartHour"
          rules={[
            {
              required: true,
              message: 'Please input start hour!',
            },
          ]}
          initialValue={localWorkStartHour}
        >
          <InputNumber
            style={{ width: isMobile ? '100%' : 200 }}
            min={0}
            max={24}
            value={localWorkStartHour}
            onChange={(v) => setLocalWorkStartHour(v)}
          />
        </Form.Item>
        <Form.Item
          label="Кінець робочого дня"
          name="workEndHour"
          rules={[
            {
              required: true,
              message: 'Please input end hour!',
            },
          ]}
          initialValue={localWorkEndHour}
        >
          <InputNumber
            style={{ width: isMobile ? '100%' : 200 }}
            min={0}
            max={24}
            value={localWorkEndHour}
            onChange={(v) => setLocalWorkEndHour(v)}
          />
        </Form.Item>
        <Form.Item
          label="Включити сповіщення про запис"
          name="notify"
          rules={[
            {
              required: true,
              message: 'Please input notify template!',
            },
          ]}
          initialValue={localIsNotify}
        >
          <Checkbox
            style={{ width: isMobile ? '100%' : 200 }}
            defaultChecked={localIsNotify}
            onChange={() => setLocalIsNotify(!localIsNotify)}
          />
        </Form.Item>
        <Form.Item
          label="Сповіщення про запис"
          name="notifyTemplate"
          rules={[
            {
              required: true,
              message: 'Please input notify template!',
            },
          ]}
          initialValue={localNewPostMessageTemplate}
        >
          <TextArea
            style={{ width: isMobile ? '100%' : 200 }}
            value={localNewPostMessageTemplate}
            onChange={(v) => setLocalNewPostMessageTemplate(v.target.value)}
            rows={4}
          />
        </Form.Item>
        <Form.Item
          label="Включити нагадування про запис"
          name="remind"
          rules={[
            {
              required: true,
              message: 'Please input remind!',
            },
          ]}
          initialValue={localIsRemind}
        >
          <Checkbox
            style={{ width: isMobile ? '100%' : 200 }}
            defaultChecked={localIsRemind}
            onChange={() => setLocalIsRemind(!localIsRemind)}
          />
        </Form.Item>
        <Form.Item
          label="Час розсилки нагадувань"
          name="remindTime"
          rules={[
            {
              required: true,
              message: 'Please input remind hour!',
            },
          ]}
          initialValue={localRemindTime}
        >
          <InputNumber
            style={{ width: isMobile ? '100%' : 200 }}
            min={0}
            max={24}
            value={localRemindTime}
            onChange={(v) => setLocalRemindTime(v)}
          />
        </Form.Item>
        <Form.Item
          label="Нагадування про запис"
          name="reminder"
          rules={[
            {
              required: true,
              message: 'Please input reminder template!',
            },
          ]}
          initialValue={localReminderMessageTemplate}
        >
          <TextArea
            style={{ width: isMobile ? '100%' : 200 }}
            value={localReminderMessageTemplate}
            onChange={(v) => setLocalReminderMessageTemplate(v.target.value)}
            rows={4}
          />
        </Form.Item>
        <Form.Item>
          <Button style={{ width: '100px', marginLeft: '100px' }} type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      </Form>
      <div>clientName, clientLastName, userName, serviceName, date, time</div>
    </>
  );
};

export default SettingsForm;
