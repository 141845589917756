import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
};

export type Client = {
  __typename?: 'Client';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addClient: Client;
  addPost: Post;
  addService: Service;
  addUser: User;
  editPost: Post;
  login: User;
  logout: Scalars['Boolean'];
  removePost: Post;
  updateSettings: Scalars['Boolean'];
};


export type MutationAddClientArgs = {
  lastName: Scalars['String'];
  name: Scalars['String'];
  phone: Scalars['String'];
};


export type MutationAddPostArgs = {
  clientId: Scalars['String'];
  comment: Scalars['String'];
  customDuration: Scalars['Float'];
  date: Scalars['DateTime'];
  notifyBefore: Scalars['Boolean'];
  remindBefore: Scalars['Boolean'];
  serviceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationAddServiceArgs = {
  duration: Scalars['Float'];
  name: Scalars['String'];
  price: Scalars['Float'];
};


export type MutationAddUserArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
  phone: Scalars['String'];
  role: Scalars['Float'];
};


export type MutationEditPostArgs = {
  clientId: Scalars['String'];
  comment: Scalars['String'];
  customDuration: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  notifyBefore: Scalars['Boolean'];
  remindBefore: Scalars['Boolean'];
  serviceId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationLoginArgs = {
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationRemovePostArgs = {
  id: Scalars['String'];
};


export type MutationUpdateSettingsArgs = {
  isNotify: Scalars['Boolean'];
  isRemind: Scalars['Boolean'];
  newPostMessageTemplate: Scalars['String'];
  remindTime: Scalars['Float'];
  reminderMessageTemplate: Scalars['String'];
  workEndHour: Scalars['Float'];
  workStartHour: Scalars['Float'];
};

export type Post = {
  __typename?: 'Post';
  authorId: Scalars['String'];
  clientId: Scalars['String'];
  comment: Scalars['String'];
  customDuration: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  notifyBefore: Scalars['Boolean'];
  remindBefore: Scalars['Boolean'];
  reminded: Scalars['Boolean'];
  serviceId: Scalars['String'];
  userId: Scalars['String'];
};

export type PostFilterInput = {
  after?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['DateTime']>;
  date?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  clients: Array<Client>;
  filterPosts: Array<Post>;
  me: User;
  posts: Array<Post>;
  services: Array<Service>;
  settings: Settings;
  users: Array<User>;
};


export type QueryFilterPostsArgs = {
  filter: PostFilterInput;
};

export type Service = {
  __typename?: 'Service';
  duration: Scalars['Float'];
  id: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
};

export type Settings = {
  __typename?: 'Settings';
  id: Scalars['String'];
  isNotify: Scalars['Boolean'];
  isRemind: Scalars['Boolean'];
  newPostMessageTemplate: Scalars['String'];
  remindTime: Scalars['Float'];
  reminderMessageTemplate: Scalars['String'];
  workEndHour: Scalars['Float'];
  workStartHour: Scalars['Float'];
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['DateTime'];
  id: Scalars['String'];
  isHidden: Scalars['Boolean'];
  lastLogin: Scalars['DateTime'];
  name: Scalars['String'];
  phone: Scalars['String'];
  role: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type AddClientMutationMutationVariables = Exact<{
  phone: Scalars['String'];
  lastName: Scalars['String'];
  name: Scalars['String'];
}>;


export type AddClientMutationMutation = { __typename?: 'Mutation', addClient: { __typename?: 'Client', id: string, name: string, lastName: string, phone: string, createdAt: any } };

export type AddPostMutationVariables = Exact<{
  remindBefore: Scalars['Boolean'];
  notifyBefore: Scalars['Boolean'];
  date: Scalars['DateTime'];
  serviceId: Scalars['String'];
  userId: Scalars['String'];
  clientId: Scalars['String'];
  comment: Scalars['String'];
  customDuration: Scalars['Float'];
}>;


export type AddPostMutation = { __typename?: 'Mutation', addPost: { __typename?: 'Post', id: string, userId: string, clientId: string, serviceId: string, comment: string, notifyBefore: boolean, reminded: boolean, date: any, authorId: string, remindBefore: boolean, customDuration: number } };

export type AddServiceMutationMutationVariables = Exact<{
  duration: Scalars['Float'];
  price: Scalars['Float'];
  name: Scalars['String'];
}>;


export type AddServiceMutationMutation = { __typename?: 'Mutation', addService: { __typename?: 'Service', id: string, name: string, price: number, duration: number } };

export type AddUserMutationMutationVariables = Exact<{
  addUserName: Scalars['String'];
  addUserPassword: Scalars['String'];
  role: Scalars['Float'];
  phone: Scalars['String'];
}>;


export type AddUserMutationMutation = { __typename?: 'Mutation', addUser: { __typename?: 'User', name: string } };

export type EditPostMutationVariables = Exact<{
  remindBefore: Scalars['Boolean'];
  serviceId: Scalars['String'];
  comment: Scalars['String'];
  userId: Scalars['String'];
  clientId: Scalars['String'];
  editPostId: Scalars['String'];
  notifyBefore: Scalars['Boolean'];
  date: Scalars['DateTime'];
  customDuration: Scalars['Float'];
}>;


export type EditPostMutation = { __typename?: 'Mutation', editPost: { __typename?: 'Post', id: string, userId: string, clientId: string, authorId: string, serviceId: string, comment: string, notifyBefore: boolean, reminded: boolean, remindBefore: boolean, date: any } };

export type LoginMutationMutationVariables = Exact<{
  loginPassword: Scalars['String'];
  loginName: Scalars['String'];
}>;


export type LoginMutationMutation = { __typename?: 'Mutation', login: { __typename?: 'User', id: string, name: string, role: number, createdAt: any, lastLogin: any, updatedAt: any } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type RemovePostMutationVariables = Exact<{
  removePostId: Scalars['String'];
}>;


export type RemovePostMutation = { __typename?: 'Mutation', removePost: { __typename?: 'Post', id: string, userId: string, clientId: string, authorId: string, serviceId: string, comment: string, notifyBefore: boolean, reminded: boolean, remindBefore: boolean, date: any } };

export type UpdateSettingsMutationVariables = Exact<{
  isRemind: Scalars['Boolean'];
  isNotify: Scalars['Boolean'];
  remindTime: Scalars['Float'];
  reminderMessageTemplate: Scalars['String'];
  newPostMessageTemplate: Scalars['String'];
  workEndHour: Scalars['Float'];
  workStartHour: Scalars['Float'];
}>;


export type UpdateSettingsMutation = { __typename?: 'Mutation', updateSettings: boolean };

export type ClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientsQuery = { __typename?: 'Query', clients: Array<{ __typename?: 'Client', id: string, name: string, lastName: string, phone: string }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: string, name: string, role: number } };

export type PostsQueryVariables = Exact<{ [key: string]: never; }>;


export type PostsQuery = { __typename?: 'Query', posts: Array<{ __typename?: 'Post', id: string, userId: string, clientId: string, authorId: string, serviceId: string, comment: string, notifyBefore: boolean, reminded: boolean, remindBefore: boolean, date: any, customDuration: number }> };

export type FilterPostsQueryVariables = Exact<{
  filter: PostFilterInput;
}>;


export type FilterPostsQuery = { __typename?: 'Query', filterPosts: Array<{ __typename?: 'Post', id: string, userId: string, clientId: string, authorId: string, serviceId: string, comment: string, notifyBefore: boolean, reminded: boolean, remindBefore: boolean, date: any, customDuration: number }> };

export type ServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesQuery = { __typename?: 'Query', services: Array<{ __typename?: 'Service', id: string, name: string, price: number, duration: number }> };

export type SettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsQuery = { __typename?: 'Query', settings: { __typename?: 'Settings', workStartHour: number, remindTime: number, workEndHour: number, isNotify: boolean, isRemind: boolean, newPostMessageTemplate: string, reminderMessageTemplate: string } };

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, role: number, name: string, phone: string }> };


export const AddClientMutationDocument = gql`
    mutation AddClientMutation($phone: String!, $lastName: String!, $name: String!) {
  addClient(phone: $phone, lastName: $lastName, name: $name) {
    id
    name
    lastName
    phone
    createdAt
  }
}
    `;
export type AddClientMutationMutationFn = Apollo.MutationFunction<AddClientMutationMutation, AddClientMutationMutationVariables>;

/**
 * __useAddClientMutationMutation__
 *
 * To run a mutation, you first call `useAddClientMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddClientMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addClientMutationMutation, { data, loading, error }] = useAddClientMutationMutation({
 *   variables: {
 *      phone: // value for 'phone'
 *      lastName: // value for 'lastName'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddClientMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddClientMutationMutation, AddClientMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddClientMutationMutation, AddClientMutationMutationVariables>(AddClientMutationDocument, options);
      }
export type AddClientMutationMutationHookResult = ReturnType<typeof useAddClientMutationMutation>;
export type AddClientMutationMutationResult = Apollo.MutationResult<AddClientMutationMutation>;
export type AddClientMutationMutationOptions = Apollo.BaseMutationOptions<AddClientMutationMutation, AddClientMutationMutationVariables>;
export const AddPostDocument = gql`
    mutation AddPost($remindBefore: Boolean!, $notifyBefore: Boolean!, $date: DateTime!, $serviceId: String!, $userId: String!, $clientId: String!, $comment: String!, $customDuration: Float!) {
  addPost(
    remindBefore: $remindBefore
    notifyBefore: $notifyBefore
    date: $date
    serviceId: $serviceId
    userId: $userId
    clientId: $clientId
    comment: $comment
    customDuration: $customDuration
  ) {
    id
    userId
    clientId
    serviceId
    comment
    notifyBefore
    reminded
    date
    authorId
    remindBefore
    customDuration
  }
}
    `;
export type AddPostMutationFn = Apollo.MutationFunction<AddPostMutation, AddPostMutationVariables>;

/**
 * __useAddPostMutation__
 *
 * To run a mutation, you first call `useAddPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPostMutation, { data, loading, error }] = useAddPostMutation({
 *   variables: {
 *      remindBefore: // value for 'remindBefore'
 *      notifyBefore: // value for 'notifyBefore'
 *      date: // value for 'date'
 *      serviceId: // value for 'serviceId'
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *      comment: // value for 'comment'
 *      customDuration: // value for 'customDuration'
 *   },
 * });
 */
export function useAddPostMutation(baseOptions?: Apollo.MutationHookOptions<AddPostMutation, AddPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPostMutation, AddPostMutationVariables>(AddPostDocument, options);
      }
export type AddPostMutationHookResult = ReturnType<typeof useAddPostMutation>;
export type AddPostMutationResult = Apollo.MutationResult<AddPostMutation>;
export type AddPostMutationOptions = Apollo.BaseMutationOptions<AddPostMutation, AddPostMutationVariables>;
export const AddServiceMutationDocument = gql`
    mutation AddServiceMutation($duration: Float!, $price: Float!, $name: String!) {
  addService(duration: $duration, price: $price, name: $name) {
    id
    name
    price
    duration
  }
}
    `;
export type AddServiceMutationMutationFn = Apollo.MutationFunction<AddServiceMutationMutation, AddServiceMutationMutationVariables>;

/**
 * __useAddServiceMutationMutation__
 *
 * To run a mutation, you first call `useAddServiceMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddServiceMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addServiceMutationMutation, { data, loading, error }] = useAddServiceMutationMutation({
 *   variables: {
 *      duration: // value for 'duration'
 *      price: // value for 'price'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAddServiceMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddServiceMutationMutation, AddServiceMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddServiceMutationMutation, AddServiceMutationMutationVariables>(AddServiceMutationDocument, options);
      }
export type AddServiceMutationMutationHookResult = ReturnType<typeof useAddServiceMutationMutation>;
export type AddServiceMutationMutationResult = Apollo.MutationResult<AddServiceMutationMutation>;
export type AddServiceMutationMutationOptions = Apollo.BaseMutationOptions<AddServiceMutationMutation, AddServiceMutationMutationVariables>;
export const AddUserMutationDocument = gql`
    mutation AddUserMutation($addUserName: String!, $addUserPassword: String!, $role: Float!, $phone: String!) {
  addUser(
    name: $addUserName
    password: $addUserPassword
    role: $role
    phone: $phone
  ) {
    name: name
  }
}
    `;
export type AddUserMutationMutationFn = Apollo.MutationFunction<AddUserMutationMutation, AddUserMutationMutationVariables>;

/**
 * __useAddUserMutationMutation__
 *
 * To run a mutation, you first call `useAddUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserMutationMutation, { data, loading, error }] = useAddUserMutationMutation({
 *   variables: {
 *      addUserName: // value for 'addUserName'
 *      addUserPassword: // value for 'addUserPassword'
 *      role: // value for 'role'
 *      phone: // value for 'phone'
 *   },
 * });
 */
export function useAddUserMutationMutation(baseOptions?: Apollo.MutationHookOptions<AddUserMutationMutation, AddUserMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUserMutationMutation, AddUserMutationMutationVariables>(AddUserMutationDocument, options);
      }
export type AddUserMutationMutationHookResult = ReturnType<typeof useAddUserMutationMutation>;
export type AddUserMutationMutationResult = Apollo.MutationResult<AddUserMutationMutation>;
export type AddUserMutationMutationOptions = Apollo.BaseMutationOptions<AddUserMutationMutation, AddUserMutationMutationVariables>;
export const EditPostDocument = gql`
    mutation EditPost($remindBefore: Boolean!, $serviceId: String!, $comment: String!, $userId: String!, $clientId: String!, $editPostId: String!, $notifyBefore: Boolean!, $date: DateTime!, $customDuration: Float!) {
  editPost(
    remindBefore: $remindBefore
    serviceId: $serviceId
    userId: $userId
    clientId: $clientId
    comment: $comment
    id: $editPostId
    notifyBefore: $notifyBefore
    date: $date
    customDuration: $customDuration
  ) {
    id
    userId
    clientId
    authorId
    serviceId
    comment
    notifyBefore
    reminded
    remindBefore
    date
  }
}
    `;
export type EditPostMutationFn = Apollo.MutationFunction<EditPostMutation, EditPostMutationVariables>;

/**
 * __useEditPostMutation__
 *
 * To run a mutation, you first call `useEditPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editPostMutation, { data, loading, error }] = useEditPostMutation({
 *   variables: {
 *      remindBefore: // value for 'remindBefore'
 *      serviceId: // value for 'serviceId'
 *      comment: // value for 'comment'
 *      userId: // value for 'userId'
 *      clientId: // value for 'clientId'
 *      editPostId: // value for 'editPostId'
 *      notifyBefore: // value for 'notifyBefore'
 *      date: // value for 'date'
 *      customDuration: // value for 'customDuration'
 *   },
 * });
 */
export function useEditPostMutation(baseOptions?: Apollo.MutationHookOptions<EditPostMutation, EditPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditPostMutation, EditPostMutationVariables>(EditPostDocument, options);
      }
export type EditPostMutationHookResult = ReturnType<typeof useEditPostMutation>;
export type EditPostMutationResult = Apollo.MutationResult<EditPostMutation>;
export type EditPostMutationOptions = Apollo.BaseMutationOptions<EditPostMutation, EditPostMutationVariables>;
export const LoginMutationDocument = gql`
    mutation LoginMutation($loginPassword: String!, $loginName: String!) {
  login(password: $loginPassword, name: $loginName) {
    id
    name
    role
    createdAt
    lastLogin
    updatedAt
  }
}
    `;
export type LoginMutationMutationFn = Apollo.MutationFunction<LoginMutationMutation, LoginMutationMutationVariables>;

/**
 * __useLoginMutationMutation__
 *
 * To run a mutation, you first call `useLoginMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutationMutation, { data, loading, error }] = useLoginMutationMutation({
 *   variables: {
 *      loginPassword: // value for 'loginPassword'
 *      loginName: // value for 'loginName'
 *   },
 * });
 */
export function useLoginMutationMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutationMutation, LoginMutationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutationMutation, LoginMutationMutationVariables>(LoginMutationDocument, options);
      }
export type LoginMutationMutationHookResult = ReturnType<typeof useLoginMutationMutation>;
export type LoginMutationMutationResult = Apollo.MutationResult<LoginMutationMutation>;
export type LoginMutationMutationOptions = Apollo.BaseMutationOptions<LoginMutationMutation, LoginMutationMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RemovePostDocument = gql`
    mutation RemovePost($removePostId: String!) {
  removePost(id: $removePostId) {
    id
    userId
    clientId
    authorId
    serviceId
    comment
    notifyBefore
    reminded
    remindBefore
    date
  }
}
    `;
export type RemovePostMutationFn = Apollo.MutationFunction<RemovePostMutation, RemovePostMutationVariables>;

/**
 * __useRemovePostMutation__
 *
 * To run a mutation, you first call `useRemovePostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePostMutation, { data, loading, error }] = useRemovePostMutation({
 *   variables: {
 *      removePostId: // value for 'removePostId'
 *   },
 * });
 */
export function useRemovePostMutation(baseOptions?: Apollo.MutationHookOptions<RemovePostMutation, RemovePostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePostMutation, RemovePostMutationVariables>(RemovePostDocument, options);
      }
export type RemovePostMutationHookResult = ReturnType<typeof useRemovePostMutation>;
export type RemovePostMutationResult = Apollo.MutationResult<RemovePostMutation>;
export type RemovePostMutationOptions = Apollo.BaseMutationOptions<RemovePostMutation, RemovePostMutationVariables>;
export const UpdateSettingsDocument = gql`
    mutation UpdateSettings($isRemind: Boolean!, $isNotify: Boolean!, $remindTime: Float!, $reminderMessageTemplate: String!, $newPostMessageTemplate: String!, $workEndHour: Float!, $workStartHour: Float!) {
  updateSettings(
    isRemind: $isRemind
    isNotify: $isNotify
    remindTime: $remindTime
    reminderMessageTemplate: $reminderMessageTemplate
    newPostMessageTemplate: $newPostMessageTemplate
    workEndHour: $workEndHour
    workStartHour: $workStartHour
  )
}
    `;
export type UpdateSettingsMutationFn = Apollo.MutationFunction<UpdateSettingsMutation, UpdateSettingsMutationVariables>;

/**
 * __useUpdateSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSettingsMutation, { data, loading, error }] = useUpdateSettingsMutation({
 *   variables: {
 *      isRemind: // value for 'isRemind'
 *      isNotify: // value for 'isNotify'
 *      remindTime: // value for 'remindTime'
 *      reminderMessageTemplate: // value for 'reminderMessageTemplate'
 *      newPostMessageTemplate: // value for 'newPostMessageTemplate'
 *      workEndHour: // value for 'workEndHour'
 *      workStartHour: // value for 'workStartHour'
 *   },
 * });
 */
export function useUpdateSettingsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSettingsMutation, UpdateSettingsMutationVariables>(UpdateSettingsDocument, options);
      }
export type UpdateSettingsMutationHookResult = ReturnType<typeof useUpdateSettingsMutation>;
export type UpdateSettingsMutationResult = Apollo.MutationResult<UpdateSettingsMutation>;
export type UpdateSettingsMutationOptions = Apollo.BaseMutationOptions<UpdateSettingsMutation, UpdateSettingsMutationVariables>;
export const ClientsDocument = gql`
    query Clients {
  clients {
    id
    name
    lastName
    phone
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    name
    role
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const PostsDocument = gql`
    query Posts {
  posts {
    id
    userId
    clientId
    authorId
    serviceId
    comment
    notifyBefore
    reminded
    remindBefore
    date
    customDuration
  }
}
    `;

/**
 * __usePostsQuery__
 *
 * To run a query within a React component, call `usePostsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostsQuery({
 *   variables: {
 *   },
 * });
 */
export function usePostsQuery(baseOptions?: Apollo.QueryHookOptions<PostsQuery, PostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
      }
export function usePostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PostsQuery, PostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PostsQuery, PostsQueryVariables>(PostsDocument, options);
        }
export type PostsQueryHookResult = ReturnType<typeof usePostsQuery>;
export type PostsLazyQueryHookResult = ReturnType<typeof usePostsLazyQuery>;
export type PostsQueryResult = Apollo.QueryResult<PostsQuery, PostsQueryVariables>;
export const FilterPostsDocument = gql`
    query FilterPosts($filter: PostFilterInput!) {
  filterPosts(filter: $filter) {
    id
    userId
    clientId
    authorId
    serviceId
    comment
    notifyBefore
    reminded
    remindBefore
    date
    customDuration
  }
}
    `;

/**
 * __useFilterPostsQuery__
 *
 * To run a query within a React component, call `useFilterPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterPostsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useFilterPostsQuery(baseOptions: Apollo.QueryHookOptions<FilterPostsQuery, FilterPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilterPostsQuery, FilterPostsQueryVariables>(FilterPostsDocument, options);
      }
export function useFilterPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilterPostsQuery, FilterPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilterPostsQuery, FilterPostsQueryVariables>(FilterPostsDocument, options);
        }
export type FilterPostsQueryHookResult = ReturnType<typeof useFilterPostsQuery>;
export type FilterPostsLazyQueryHookResult = ReturnType<typeof useFilterPostsLazyQuery>;
export type FilterPostsQueryResult = Apollo.QueryResult<FilterPostsQuery, FilterPostsQueryVariables>;
export const ServicesDocument = gql`
    query Services {
  services {
    id
    name
    price
    duration
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const SettingsDocument = gql`
    query settings {
  settings {
    workStartHour
    remindTime
    workEndHour
    isNotify
    isRemind
    newPostMessageTemplate
    reminderMessageTemplate
  }
}
    `;

/**
 * __useSettingsQuery__
 *
 * To run a query within a React component, call `useSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSettingsQuery(baseOptions?: Apollo.QueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
      }
export function useSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingsQuery, SettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingsQuery, SettingsQueryVariables>(SettingsDocument, options);
        }
export type SettingsQueryHookResult = ReturnType<typeof useSettingsQuery>;
export type SettingsLazyQueryHookResult = ReturnType<typeof useSettingsLazyQuery>;
export type SettingsQueryResult = Apollo.QueryResult<SettingsQuery, SettingsQueryVariables>;
export const UsersDocument = gql`
    query Users {
  users {
    id
    role
    name
    phone
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;