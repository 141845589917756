import { CalendarOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Calendar, DatePicker, Popover } from 'antd';
import { Button } from 'antd/lib/radio';
import moment from 'moment';
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import styles from './CustomToolbar.module.scss';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

interface ICustomToolbar {
  updateDate: Dispatch<SetStateAction<Date>>;
  date: Date;
}
const getMonday = (d: Date) => {
  d = new Date(d);
  const day = d.getDay(),
    diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
  return new Date(d.setDate(diff));
};
const datesAreOnSameDay = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 20,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 12,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 6,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const generateDayList = (date: Date) => {
  const res = [];
  for (let i = 0; i < 60; i++) {
    const nextday = moment(date).add(i, 'd').toDate();
    res.push(nextday);
  }
  for (let i = 30; i > 0; i--) {
    const nextday = moment(date).add(-i, 'd').toDate();
    res.push(nextday);
  }
  return res;
}
export const CustomToolbar: FC<ICustomToolbar> = ({ updateDate, date }) => {
  const [currentDate, setCurrentDate] = useState<Date>(new Date(date));
  const [dayList, setDayList] = useState<Date[]>([]);
  useEffect(() => {
    if (new Date(date).toString() !== currentDate.toString()) updateDate(new Date(currentDate));
  }, [currentDate]);

  const onDateChange = useCallback((newDate) => {
    setCurrentDate(newDate.toDate());
  }, []);


  useEffect(() => {
      setDayList(generateDayList(currentDate));
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.datePickerWrapper}>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={true}
          autoPlay={false}
          transitionDuration={1}
          containerClass="carousel-container"
          removeArrowOnDeviceType={['tablet', 'mobile']}
          deviceType={'mobile'}
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {
              dayList.map((date, index) => (
              <div key={index}  onClick={() => setCurrentDate(date) }>
                <div  className={`${styles.dayCard} ${datesAreOnSameDay(date, currentDate) ? styles.activeDate : ''}`}>
                  <div>{moment(date).format('ddd')}</div>
                  <div>{moment(date).format('D MMM')}</div>
                </div>
              </div>
              ))
            }
        </Carousel>
      </div>
      <div className={styles.dateInfo}>
        <Popover
          content={
            <Calendar
              value={moment(currentDate)}
              onChange={onDateChange}
              className={styles.calendar}
              fullscreen={false}
            />
          }
          title="Виберіть дату"
          trigger="click"
        >
          <Button>
            <CalendarOutlined />
          </Button>
        </Popover>{' '}
        Вибрано: {moment(currentDate).format('DD.MM.YYYY')}
      </div>
    </div>
  );
};
